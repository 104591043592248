import React from 'react';
import { Layout, Col, Card } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import screenshotMain2 from '../assets/screenshot_main2.png';
import TE1 from '../articles/TE1.png';
import TE2 from '../articles/TE2.png';
import TE3 from '../articles/TE3.png';
import TE4 from '../articles/TE4.png';
import TE5 from '../articles/TE5.png';
import TE6 from '../articles/TE6.png';
import TE7 from '../articles/TE7.png';
import TE8 from '../articles/TE8.png';
import TE9 from '../articles/TE9.png';
import TE10 from '../articles/TE10.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function TagEditorArticle() {
  return (
    <Content>
      <div className={styles.pageArticle}>
        <div className={styles.pageArticleContainer}>
          <h1>AWS Tag Editor, how does it work?</h1>
          <p>
            Tags are{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              key-value
            </span>{' '}
            pairs that allow you to organize your AWS resources into groups.
          </p>
          <p>
            When tags are applied to your resources, you can use them to perform
            any of the following useful tasks:
            <br />
            <br />
            <span style={{ fontStyle: 'italic' }}>
              - Use tags in conjunction with "Resources Groups" to help you view
              tagged resource information in one place, rather than switching
              between multiple AWS consoles or regions.
              <br />
              - View billing information for tagged resources using "Cost
              Explorer" and "Cost and usage Report.
              <br />
              - Send notifications about spending limits related to specific
              tags using AWS Budget.
              <br />
            </span>
          </p>
          <p>You can easily set up your tags by following this step:</p>
          <p>
            Before you start, sketch out a plan for the{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              key-value
            </span>{' '}
            tags that will help you organize your resources. For example, you
            might want all resources to have tag keys like{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              Project
            </span>{' '}
            and{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              Environment
            </span>
            . Also keep in mind that each resource can have no more than 50
            tags.
          </p>
          <p>
            Next, let's go to the <b>Tag Editor</b> tool.
            <br />
            To open <b>Tag Editor</b> log into your AWS console. Then in the
            search bar, look for the Tag Editor feature.
          </p>
          <img src={TE1} alt={'TE1'} />
          <p>
            Welcome aboard <b>Tag Editor.</b>
            <br />
            Once you are on the tool, you will be able to find all the resources
            in your account.
          </p>
          <img src={TE2} alt={'TE2'} />
          <p>
            First, we will select the region(s) applied to the different
            resources we want to process.
          </p>
          <img src={TE3} alt={'TE3'} />
          <p>
            Then select the resource type(s) related to the previously
            configured regions.
          </p>
          <img src={TE4} alt={'TE4'} />
          <p>
            The following fields are optional, they will allow you to refine
            your search but are not necessary to continue your procedure.
          </p>
          <p>
            When your <b>regions</b> and <b>resource types</b> have been chosen,
            click on <b>Search Resources</b>. Once the loading process is
            complete, the resources will appear at the bottom of your page.
          </p>
          <p>
            Click on the settings button on the right side of the interface to
            open the preferences menu.
          </p>
          <img src={TE5} alt={'TE5'} />
          <p>
            You will be able to set up your user interface and especially to
            choose the visible columns for your resources.{' '}
            <span style={{ fontStyle: 'italic' }}>
              If you haven't configured any of your tags yet, we will come back
              to this step later.
            </span>
            <br />
            Click on <b>confirm</b> to apply your changes.
          </p>
          <img src={TE6} alt={'TE6'} />
          <p>Back on Tag Editor: several options are available to you.</p>
          <p>
            You have the possibility to check the box at the top left to edit
            all your Tags. In this case, you will modify your resources
            simultaneously.
          </p>
          <p>
            In case you only want to manage one or more separate resources
            -example below-, check the boxes separately and then click on{' '}
            <b>Manage tags of the selected resources.</b>
          </p>
          <img src={TE7} alt={'TE7'} />
          <p>
            You will then be taken to the tag modification interface for the
            selected resources.
          </p>
          <img src={TE8} alt={'TE8'} />
          <p>You can add tags, modify them or delete an existing one.</p>

          <p>
            It is best to select resources separately when you want to add
            separate tags to keep them separate.
          </p>
          <p>
            You can click on the setup button again to display only the columns
            containing the resources with the new tags you just created.
          </p>
          <img src={TE9} alt={'TE9'} />
          <p>Finally you can export your resources in CSV format.</p>
          <img src={TE10} alt={'TE10'} />
          <p style={{ fontStyle: 'italic' }}>
            Choose one of the two options and you're done!
          </p>
          <p>
            Although it is easy to use, the <b>Tag Editor</b> lacks a number of
            important features. We would have liked to see more precise
            statistics about the efficiency of our tags or different advices
            about the best practices to adopt when tagging, directly integrated
            in the console. Nevertheless, this tool remains very interesting in
            order to train you to the basics and to the different good practices
            of tagging; before, why not, launching yourself into the big bath
            with solutions more adapted to your desires.
          </p>
        </div>
      </div>
    </Content>
  );
}
