import React from 'react';
import { Layout, Button, Card } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import Video from '../assets/Tagbot2.mp4';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Cut() {
  return (
    <Content className={styles.cut} style={{ overflow: 'hidden' }}>
      <Row
        style={{
          zIndex: 10,
          position: 'relative',
          padding: '0 25px 0',
        }}
      >
        <Row>
          <h1 className={styles.cutTitle}>
            TAGBOT HELPS MANAGE
            <br />
            YOUR AMAZON WEB SERVICES RESOURCES.
          </h1>
        </Row>
        <Row>
          <span className={styles.cutDesc}>
            The only efficient tagging tool essential to running your AWS cloud.
          </span>
        </Row>
        <div className={styles.butSchedule}>
          <Button
            className={styles.button}
            data-ci="cut-button-get-a-demo"
            size="large"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://app.tagbot.cloud/register';
            }}
          >
            Start Your 2 Week Free Trial Today!
            <br />
            No Credit Card Required
          </Button>
        </div>
        <video controls className={styles.imgPresentation}>
          <source src={Video} type={'video/mp4'} />
        </video>
        {/*<img
          className={styles.imgPresentation}
          src={screenshotMain2}
          alt={'screenshotMain'}
        />*/}
      </Row>
      {/*<div className={styles.cutAccent}></div>*/}
    </Content>
  );
}
